<template>
  <div>
    <!-- MOBILE -->
    <div v-if="isMobileWidth === 1" class="mb-5 pb-10" style="height:100%">
      <div v-if="reload" class="row pl-3 pr-3 d-flex justify-space-between pt-3">
        <v-card
          width="100%"
          height="270px"
          class="round mb-3"
          elevation="0"
          outlined
          v-for="n in 3"
          :key="n+'A'"
        >
        <v-skeleton-loader
          type="card"
        ></v-skeleton-loader>
        </v-card>
      </div>
      <div v-else>
        <v-row>
          <v-col cols="12" class="pt-2">
            <v-card class="round">
              <v-img height="300" :src="dataPackage.photo" />
            </v-card>
            <p class="text-18 font-weight-semibold pt-2 black--text mb-1">{{ dataPackage.name }}</p>
            <p class="mb-0 text-14 primary--text font-weight-bold"><i class="fas fa-tag mr-1"></i> IDR {{dataPackage.price | numberFormat}}</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col  cols="12" class="pt-0 text-center">
            <v-btn @click="buy(dataPackage.name)" elevation="0" block rounded class="text-capitalize mb-2 mt-0" color="primary"><i class="fas fa-shopping-cart mr-2"></i>Buy Package</v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="pt-1">
            <v-card class="round" elevation="0" outlined>
              <v-card-text class="black--text">
                <p class="font-weight-bold text-14 black--text mb-2">Deskripsi :</p>
                <p v-for="desk in dataPackage.desk" :key="desk.num">{{desk.num}}. {{desk.desk}}</p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pt-1">
            <v-card class="round" elevation="0" outlined>
              <v-card-text class="black--text">
                <p class="font-weight-bold text-14 black--text mb-2">Syarat & Ketentuan :</p>
                <p>1. Penawaran berlaku 14 hari setelah Quotation Approval di tandatangani pihak brand.</p>
                <p>2. Pembayaran yang sudah di lakukan di VDC Management tidak dapat ditarik kembali.</p>
                <p>3. Klien bertanggung jawab penuh atas penggunaan nama brand dan materi yang digunakan.</p>
                <p>4. Materi iklan tidak mengandung unsur kekerasan, pornografi, SARA, ataupun materi yang melanggar perundang-undangan yang berlaku di Indonesia dan/atau norma kesusilaan di masyarakat.</p>
                <p>5. Dilarang untuk membuat konten yang bertujuan dan/atau mengakibatkan timbulnya kesan, penilaian, citra publik buruk pada produk pesaing, atau terhadap pihak manapun juga.</p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>

    <!-- DESKTOP -->
    <div v-else class="mb-5 pb-10" >
      <div v-if="reload" class="row pl-3 pr-3 d-flex justify-space-between pt-3">
        <v-card
          width="100%"
          height="270px"
          class="round mb-3"
          elevation="0"
          outlined
          v-for="n in 3"
          :key="n+'A'"
        >
        <v-skeleton-loader
          type="card"
        ></v-skeleton-loader>
        </v-card>
      </div>
      <div v-else>
        <v-card class="round" elevation="0">
          <v-card-text>
            <v-row>
              <v-col cols="4" class="pt-2">
                <v-card class="round" elevation="0">
                  <v-img height="300" :src="dataPackage.photo" />
                </v-card>
              </v-col>
              <v-col cols="8">
                <p class="title font-weight-semibold black--text mb-2 text-capitalize">{{ dataPackage.name }}</p>
                <p class="mb-0 text-18 primary--text font-weight-bold"><i class="fas fa-tag mr-1"></i> IDR {{dataPackage.price | numberFormat}}</p>
                <v-btn @click="buy(dataPackage.name)" rounded class="text-capitalize mb-2 mt-3" color="primary"><i class="fas fa-shopping-cart mr-2"></i>Buy Package</v-btn>
                <p class="font-weight-bold text-14 black--text mb-2 mt-3">Deskripsi :</p>
                <p class="mb-1" v-for="desk in dataPackage.desk" :key="desk.num">{{desk.num}}. {{desk.desk}}</p>
                <!-- <v-btn @click="buy(dataPackage.name)" rounded class="text-capitalize mb-2 mt-3" color="primary"><i class="fas fa-shopping-cart mr-2"></i>Buy Package</v-btn> -->
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card class="round mt-5">
          <v-card-text>
            <v-row>
              <!-- <v-col cols="6">
                <v-card class="round" elevation="0" outlined>
                  <v-card-text class="black--text">
                    <p class="font-weight-bold text-14 black--text mb-2">Deskripsi :</p>
                    <p v-for="desk in dataPackage.desk" :key="desk.num">{{desk.num}}. {{desk.desk}}</p>
                  </v-card-text>
                </v-card>
              </v-col> -->
              <v-col cols="12">
                <v-card class="round" elevation="0" outlined>
                  <v-card-text class="black--text">
                    <p class="font-weight-bold text-14 black--text mb-2">Syarat & Ketentuan :</p>
                    <p>1. Penawaran berlaku 14 hari setelah Quotation Approval di tandatangani pihak brand.</p>
                    <p>2. Pembayaran yang sudah di lakukan di VDC Management tidak dapat ditarik kembali.</p>
                    <p>3. Klien bertanggung jawab penuh atas penggunaan nama brand dan materi yang digunakan.</p>
                    <p>4. Materi iklan tidak mengandung unsur kekerasan, pornografi, SARA, ataupun materi yang melanggar perundang-undangan yang berlaku di Indonesia dan/atau norma kesusilaan di masyarakat.</p>
                    <p>5. Dilarang untuk membuat konten yang bertujuan dan/atau mengakibatkan timbulnya kesan, penilaian, citra publik buruk pada produk pesaing, atau terhadap pihak manapun juga.</p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  props:['data'],
  setup() {
    return {
      isLoading:true,
      reload:true,
      isMobileWidth:0,
      idPackage:'',
      dataPackage:'',
    }
  },
  mounted(){
    let item = this.$route.query.item
    item = this.decryptData(item)
    this.idPackage = item

    if(item > 0){
      if(this.isMobileWidth === 0){
        if (localStorage.getItem('reloaded')) {
            localStorage.removeItem('reloaded');
            this.reload = false
        } else {
            localStorage.setItem('reloaded', '1');
            location.reload();
        }
        window.scrollTo(0,0);
      }
      this.loadPackage();
    } else {
      this.$router.push({name:'/'});
    }

    window.scrollTo(0,0);
    this.isMobile()
    window.addEventListener('resize', () => {
      this.isMobile()
    })
  },
  methods:{
    decryptData(val) {
      let secretKey = 'U2FsdGVkX19Om4wikNyowykfw0xVZpvjyfZbNH/t5xg=';
      let decryptData = this.$CryptoJS.AES.decrypt(val, secretKey).toString(this.CryptoJS.enc.Utf8);
      return decryptData;
    },
    loadPackage(){
      this.isLoading = true;
      axios.get(
        process.env.VUE_APP_API_ENDPOINT + 'packages/getOnePackageHome/'+this.idPackage)
        .then(response => {
          if(response){
            let data = response.data;
            let dataArray =  data.description.split(',')
            let desk = []
            for(let a = 0; a<dataArray.length; a++){
              let ddd = {
                'num':a+1,
                'desk': dataArray[a]
              }
              desk.push(ddd)
            }
            data.desk = desk
            axios({
              // url: process.env.VUE_APP_API_ENDPOINT + 'influencers/getImage/'+data[a].photo,
              url: process.env.VUE_APP_API_ENDPOINT + 'packages/getImage/'+data.photo,
              method: 'GET',
              responseType: 'blob',
            }).then((res) => {
              const blob = new Blob([res.data], { type: "application/JPG" });
              data.photo = URL.createObjectURL(blob);
            });

            this.dataPackage = data
            setTimeout(() => {
              this.isLoading = false
              this.loadingTable = false
            }, 500);
          }
        }).catch(error => {
          console.log(error)
      });
    },
    buy(title){
      window.open('https://wa.me/6285691701929?text=Halo Admin, Saya tertarik untuk order paket *'+title+'*. Mohon dibantu proses ya. \n Terima kasih', '_blank');
    },
    goToLogin(){
      this.$router.push({name:'login'});
    },
    isMobile() {
      if(window.innerWidth < 778){
        this.isMobileWidth = 1
      } else if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        this.isMobileWidth = 1
      }else {
        this.isMobileWidth = 0
      }
    },
  },
  filters:{
    numberFormat (value) {
      return Intl.NumberFormat().format(value).replace(/,/g,'.');
    },
    nFormatter(num) {
      if (num >= 1000000000) {
          return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
      }
      if (num >= 1000000) {
          return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
      }
      if (num >= 1000) {
          return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
      }
      return num;
    }
  }
}
</script>
